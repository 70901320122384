<template>
  <a-menu-item
	class="menu__item"
    v-if="!itemData.hidden && hasOneShowingChild(itemData.children)"
    :key="itemData.parentPath"
    @click="handClick(itemData)"
  >
    <template #icon v-if="itemData.meta && itemData.meta.icon">
      <Icon style="font-size: 18px;" :icon="itemData.meta.icon"></Icon>
    </template>
    {{
      itemData.meta
        ? $te(itemData.meta.title)
          ? $t(itemData.meta.title)
          : itemData.meta.title
        : ""
    }}
  </a-menu-item>
  <a-sub-menu
	class="menu__item"
    v-else-if="!itemData.hidden"
    :title="
      itemData.meta
        ? $te(itemData.meta.title)
          ? $t(itemData.meta.title)
          : itemData.meta.title
        : ''
    "
    :key="itemData.parentPath"
  >
    <template #icon v-if="itemData.meta && itemData.meta.icon">
      <Icon style="font-size: 18px;" :icon="itemData.meta.icon"></Icon>
    </template>
    <sideItem
      v-for="subItem in itemData.children"
      :key="subItem.parentPath"
      :itemData="subItem"
    ></sideItem>
  </a-sub-menu>
</template>

<script>
import { Icon } from "@/components/icon/icon.js";
export default {
  props: ["itemData"],
  components: { Icon },
  methods: {
    hasOneShowingChild(children = []) {
      const showingChildren = children.filter((item) => {
        if (item.hidden) {
          return false;
        } else {
          return true;
        }
      });
      if (showingChildren.length === 0) {
        return true;
      }
      return false;
    },
    handClick(item) {
      this.$router.push({ path: item.parentPath });
    },
  },
};
</script>

<style>
	.menu__item  .ant-menu-title-content {
		font-size: 16px;
		font-weight: 500
	}
</style>