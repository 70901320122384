let isLogin = getStorage("isLogin", true);
let userInfo = getStorage("userInfo", true);
let permissionCodes = getStorage("permissionCodes", true);
let collapsed = getStorage("collapsed", true);
let language = getStorage("language") || "zh";
let menuList = getStorage("menuList", true);
let isH5 = getStorage("isH5", true) || false;

const state = {
  isLogin, // 是否登录
  userInfo, // 用户信息
  isH5, // 是否移动端
  collapsed, // 是否缩进布局
  sideRouter: [], // 左侧导航栏
  menuList, //接口返回路由数据缓存
  permissionCodes, // 路由权限唯一标识
  language, // 当前语言
  visitedViews: [], // 导航栏数据
  cachedViews: [], // 已缓存页面路由数据
  loading: false, // 页面展示loading菊花图
  ruleSelectDataIds: {}
};

const mutations = {
  setLogin(state, value) {
    state.isLogin = value;
    setStorage("isLogin", value, true);
  },
  setUserInfo(state, value) {
    state.userInfo = value;
    setStorage("userInfo", value, true);
  },
  setCollapsed(state, value) {
    state.collapsed = value;
    setStorage("collapsed", value, true);
  },
  setIsH5(state, value) {
    state.isH5 = value;
    setStorage("isH5", value, true);
  },
  set_router(state, value) {
    state.sideRouter = value;
  },
  setPermission(state, value) {
    state.permissionCodes = value;
    setStorage("permissionCodes", value, true);
  },
  set_menuList(state, value) {
    state.menuList = value;
    setStorage("menuList", value, true);
  },
  set_language(state, value) {
    state.language = value;
    setStorage("language", value);
  },
  setLoading(state, value) {
    state.loading = value;
  },
  setCachedViews(state, value) {
    if (state.cachedViews.includes(value.path)) return;
    state.visitedViews.push(value);
    state.cachedViews.push(value.path);
  },
  updateCachedViews(state, value) {
	let index = state.cachedViews.indexOf(value.path);
	state.cachedViews.splice(index, 1);
	state.visitedViews[index] = value;
	state.cachedViews.splice(index, 0, value.path);
  },
  delCachedViews(state, value) {
    const index = state.cachedViews.indexOf(value.path);
    if (index > -1) {
      state.cachedViews.splice(index, 1);
      state.visitedViews.splice(index, 1);
    }
  },
  delAllViews(state) {
    state.cachedViews = [];
    state.visitedViews = [];
  },
  setRuleDataIds(state, value) {
	state.ruleSelectDataIds[value.code] = value;
  },
  clearRuleDataIds(state, value) {
	state.ruleSelectDataIds = {};
  }
};

const actions = {
  logout({ commit }) {
    commit("setLogin", false);
    commit("setUserInfo", {});
    commit("setCollapsed", false);
    commit("delAllViews", null);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};

function setStorage(key, value, isSession) {
  let obj = {
    obj: value,
  };
  if (isSession) {
    window.sessionStorage.setItem(key, JSON.stringify(obj));
  } else {
    window.localStorage.setItem(key, JSON.stringify(obj));
  }
}

function getStorage(key, isSession) {
  if (isSession) {
    if (window.sessionStorage.getItem(key)) {
      return JSON.parse(window.sessionStorage.getItem(key)).obj;
    } else {
      return undefined;
    }
  } else {
    if (window.localStorage.getItem(key)) {
      return JSON.parse(window.localStorage.getItem(key)).obj;
    } else {
      return undefined;
    }
  }
}
