import { loginIgnore, asyncRoutes, router } from "@/router/index.js";
import store from "@/store/index.js";

/**
 * 加载路由
 */
export async function initRouter() {
  // 获取拥有访问权限的路由信息
  let newRouter = filterAsyncRoutes(
    asyncRoutes,
    store.state.system.permissionCodes || [],
    ""
  );
  store.commit("system/set_router", JSON.parse(JSON.stringify(newRouter)));

  newRouter.forEach((item) => {
    if (item.children && item.children.length) {
      let flatRoutes = flatRouterTrans(
        item.children,
        [{ path: item.path, title: item.meta.title }],
        item.path
      );
      item.children = flatRoutes;
    }
    router.addRoute(item);
  });
  // 加载路由守卫
  loadGuards();
}

function filterAsyncRoutes(routes, permissions, parentPath) {
  const res = [];
  routes.forEach((route) => {
    const tmp = { ...route };
    if (tmp.path.startsWith("/")) {
      tmp.parentPath = tmp.path;
    } else {
      tmp.parentPath =
        parentPath + (parentPath.endsWith("/") ? "" : "/") + tmp.path;
    }
    if (hasPermission(permissions, tmp)) {
      if (tmp.meta) {
        tmp.meta.hasPermission = true;
      } else {
        tmp.meta = { hasPermission: true, title: '新页面' };
      }
    } else {
      if (tmp.meta) {
        tmp.meta.hasPermission = false;
        tmp.hidden = tmp.hidden !== undefined ? tmp.hidden : true;
      } else {
        tmp.meta = { hasPermission: false, title: '新页面' };
        tmp.hidden = tmp.hidden !== undefined ? tmp.hidden : true;
      }
    }
	if (tmp.children && tmp.children.length) {
	  if (!tmp.redirect) {
	    let length = tmp.children.length;
	    tmp.hidden = tmp.hidden !== undefined ? tmp.hidden : false;
	    for (let i = length - 1; i >= 0; i--) {
	      if (hasRedirectPermission(permissions, tmp.children[i])) {
			tmp.hidden = tmp.hidden !== undefined ? tmp.hidden : false;
	        if (tmp.children[i].path.startsWith("/")) {
	          tmp.redirect = tmp.children[i].path;
	        } else {
	          tmp.redirect = tmp.parentPath + "/" + tmp.children[i].path;
	        }
	      }
	    }
	  }
	  tmp.children = filterAsyncRoutes(
	    tmp.children,
	    permissions,
	    tmp.parentPath
	  );
	}
    res.push(tmp);
  });
  return res;
}

function flatRouterTrans(routes, breadcrumb, baseUrl = "") {
  let res = [];
  routes.forEach((route) => {
    if (route.children && route.children.length) {
      let childrenBaseUrl = "";
      if (baseUrl == "") {
        childrenBaseUrl = route.path;
      } else if (route.path != "") {
        childrenBaseUrl = `${baseUrl}${baseUrl.endsWith("/") ? "" : "/"}${
          route.path
        }`;
      }
      let childrenBreadcrumb = [...breadcrumb];
      if (route.meta.breadcrumb !== false) {
        childrenBreadcrumb.push({
          path: childrenBaseUrl,
          title: route.meta.title,
        });
      }
      let tmpRoute = { ...route };
      tmpRoute.path = childrenBaseUrl;
      tmpRoute.meta.breadcrumbNeste = childrenBreadcrumb;
      delete tmpRoute.children;
      res.push(tmpRoute);
      let childrenRoutes = flatRouterTrans(
        route.children,
        childrenBreadcrumb,
        childrenBaseUrl
      );
      if (childrenRoutes && childrenRoutes.length) {
        childrenRoutes.map((item) => {
          // 如果 path 一样则覆盖，因为子路由的 path 可能设置为空，导致和父路由一样，直接注册会提示路由重复
          if (res.some((v) => v.path == item.path)) {
            res.forEach((v, i) => {
              if (v.path == item.path) {
                res[i] = item;
              }
            });
          } else {
            res.push(item);
          }
        });
      }
    } else {
      let tmpRoute = { ...route };
      if (baseUrl != "") {
        if (tmpRoute.path != "") {
          tmpRoute.path = baseUrl.endsWith("/") ? `${baseUrl}${tmpRoute.path}` : `${baseUrl}/${tmpRoute.path}`;
        } else {
          tmpRoute.path = baseUrl;
        }
      }
      // 处理面包屑导航
      let tmpBreadcrumb = [...breadcrumb];
      if (tmpRoute.meta.breadcrumb !== false) {
        tmpBreadcrumb.push({
          path: tmpRoute.path,
          title: tmpRoute.meta.title,
        });
      }
      tmpRoute.meta.breadcrumbNeste = tmpBreadcrumb;
      if (tmpRoute?.meta?.affix) {
        // 固钉页面处理，初始化时写入缓存
        store.commit("system/setCachedViews", { ...tmpRoute, fullPath: tmpRoute.path });
      }
      res.push(tmpRoute);
    }
  });
  return res;
}

function hasPermission(permissions, route) {
  if (route.meta && route.meta.permission) {
    return isContainItem(permissions, route.meta.permission);
  } else if (route.children && checkGroupPermission(route)) {
    return isChildrenPermission(permissions, route);
  } else {
    return true;
  }
}

function isContainItem(items, item) {
  for (let z = 0; z < items.length; z++) {
    const element = items[z];
    if (element && element === item) {
      return true;
    }
  }
  return false;
}

function hasRedirectPermission(permissions, route) {
  // const permissions = roles.permissions
  // 处理最后一级状态
  if (!route.children || route.children.length == 0) {
    if (route.meta && route.meta.permission) {
      if (isContainItem(permissions, route.meta.permission)) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }
  var result = false;
  if (route.children) {
    for (let j = 0; j < route.children.length; j++) {
      result = result || hasRedirectPermission(permissions, route.children[j]);
    }
  }
  return result;
}

function isChildrenPermission(permissions, item) {
  // 是否存在有权限的子路由
  let result = false;
  for (let n = 0; n < item.children.length; n++) {
    const element = item.children[n];
    if (hasPermission(permissions, element)) {
      result = true;
    }
  }
  return result;
}

function checkGroupPermission(item) {
  // 是否存在配置了permission的子路由
  let result = false;
  for (let g = 0; g < item.children.length; g++) {
    const element = item.children[g];
    if (element.meta && element.meta.permission) {
      result = true;
    }
  }
  return result;
}

// 全局前置路由守卫
function loadGuards() {
  router.beforeEach(function (to, from, next) {
	  // && !to?.meta?.affix
    if (store.state.system.cachedViews.includes(to.path) ) {
      // 进入相同路由时, 判断参数是否一致, 不一致时清除缓存
      // 创建组件时, 组件的name不在keep-alive 的inlcude中或者在exlude中则会直接返回vnode（没有取缓存）
      let index = store.state.system.cachedViews.indexOf(to.path);
	  if (to?.meta?.affix) {
		store.commit("system/updateCachedViews", to);  
	  } else if (store.state.system.visitedViews[index].fullPath !== to.fullPath) {
        store.commit("system/delCachedViews", to);
      }
    }
    if (loginIgnore.includes(to)) {
      next();
    } else {
      if (!store.state.system.isLogin) {
        next("/login");
      } else if (to.name === '404') {
		  next('/exception/404');
	  } else if (to.meta.hasPermission) {
        next();
      } else {
        // 无权限
        next("/exception/403");
      }
    }
  });

  router.beforeResolve(async (to) => {
    // 路由页面缓存写入
	// if (loginIgnore.includes(to)) return;
    if (to.path.indexOf("/login") !== -1 || to?.meta?.affix) return;
    store.commit("system/setCachedViews", to);
  });
}