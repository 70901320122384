import {
	createRouter,
	createWebHistory
} from "vue-router";
import layout from "../layout/index";
import {
	initRouter
} from "@/utils/routerUtil.js";

import propertyChildren from './modules/propertyChild.js';
import inventoryChild from './modules/inventoryChild.js';
// import propertyManageChild from './modules/propertyManageChild.js';
// import inventoryManageChild from './modules/inventoryManageChild.js';
import warnChild from './modules/warnChild.js';
// import warnManageChild from './modules/warnManageChild.js';
import systemChild from './modules/systemChild.js';
import projectChild from './modules/project.js';

// 不需要登录拦截的路由配置
export const loginIgnore = {
	names: ["page404", "page403"], //根据路由名称匹配
	paths: ["/login", "/exception/404", "/exception/403"], //根据路由fullPath匹配
	/**
	 * 判断路由是否包含在该配置中
	 * @param route vue-router 的 route 对象
	 * @returns {boolean}
	 */
	includes(route) {
		return this.paths.includes(route.path);
	},
};

// 不需要展示到左侧菜单栏的路由
export const constantRoutes = [{
		path: "/login",
		name: "login",
		component: () => import("@/views/login/index"),
	}, {
		path: "/exception",
		name: "exception",
		meta: {
			title: '异常页'
		},
		redirect: '/404',
		hidden: true,
		component: layout,
		children: [{
			path: '404',
			name: "page404",
			meta: {
				title: '404'
			},
			hidden: true,
			component: () => import("@/views/exception/404"),
		}, {
			path: '403',
			name: "page403",
			meta: {
				title: '403'
			},
			hidden: true,
			component: () => import("@/views/exception/403"),
		}]
	},
];

export let asyncRoutes = [{
		path: "/",
		name: "home",
		component: layout,
		redirect: "/jobs/index",
		meta: {
			title: "首页",
			icon: "HomeOutlined",
		},
		hidden: true,
		// children: [],
	},
	{
		path: "/index",
		name: "index",
		meta: {
			title: "",	// 仪表盘
		},
		hidden: true,
		component: layout,
		children: [{
			path: "welcome",
			name: "index_welcome",
			hidden: true,
			meta: { 
				title: '欢迎登录'
			},
			component: () => import("@/views/index/index"),
		}]
	},
	{
		path: "/jobs",
		name: "jobs",
		meta: {
			title: "我的工作台",
			icon: "DesktopOutlined",
			permission: "pc_jobs"
		},
		component: layout,
		children: [{
			path: "index",
			name: "jobsIndex",
			meta: {
				title: '我的工作台',
				permission: "pc_jobs",
				// affix: true
			},
			hidden: true,
			component: () => import("@/views/jobs/index.vue")
		}, {
			path: "todoList",
			name: "jobsTodoList",
			meta: {
				title: '我的待办',
				permission: "pc_jobs_todo"
			},
			hidden: true,
			component: ()=> import("@/views/jobs/todoList/index.vue")
		}]
	},
	{
		path: "/print",
		name: "print",
		meta: {
			title: "打印标签",
			icon: "PrinterOutlined",
			permission: "pc_print"
		},
		component: layout,
		children: [{
			path: "index",
			name: "printIndex",
			meta: {
				title: '打印标签',
				permission: "pc_print"
			},
			hidden: true,
			component: () => import("@/views/print/index.vue")
		}]
	}, 
	{
		path:"/project",
		name:"project",
		meta:{
			title:"在建工程管理",
			icon:"UserOutlined",
			permission: "pc_project"
		},
		component:layout,
		children: projectChild
	},
	{
		path: "/property",
		name: "property",
		meta: {
			title: '资产管理',
			icon: "PieChartOutlined",
			permission: "pc_assets"
		},
		component: layout,
		children: propertyChildren
	}, 
	{
		path: "/inventory",
		name: "inventory",
		meta: {
			icon: "FileDoneOutlined",
			title: '盘点管理',
			permission: "pc_inventory"
		},
		component: layout,
		children: inventoryChild
	},
	{
		path: "/warn",
		name: "warn",
		meta: {
			icon: "AlertOutlined",
			title: '预警管理',
			permission: "pc_warn"
		},
		component: layout,
		children: warnChild
	},
	{
		path: "/literature",
		name: "literature",
		meta: {
			icon: "FileSearchOutlined",
			title: "文献查询",
			permission: "pc_literature"
		},
		component: layout,
		children: [{
			path: "search",
			name: "literatureSearch",
			meta: {
				title: '文献查询',
				permission: "pc_literature"
			},
			hidden: true,
			component: ()=> import("@/views/literature/search/index.vue")
		}]
	},
	{
		path: "/statistics",
		name: "statistics",
		meta: {
			icon: "AreaChartOutlined",
			title: '数据统计',
			permission: "pc_statistics"
		},
		component: layout,
		children: [{
			path: 'index',
			name: "statisticsIndex",
			meta: {
				title: '数据统计',
				permission: "pc_statistics"
			},
			hidden: true,
			component: () => import("@/views/statistics/index.vue")
		}]
	},
	// {
	// 	path: "/propertyManage",
	// 	name: "propertyManage",
	// 	meta: {
	// 		icon: "PieChartOutlined",
	// 		title: '资产管理'
	// 	},
	// 	component: layout,
	// 	children: propertyManageChild
	// },
	// {
	// 	path: "/inventoryManage",
	// 	name: "inventoryManage",
	// 	meta: {
	// 		icon: "FileDoneOutlined",
	// 		title: '盘点管理'
	// 	},
	// 	component: layout,
	// 	children: inventoryManageChild
	// },
	// {
	// 	path: "/warnManage",
	// 	name: "warnManage",
	// 	meta: {
	// 		icon: "AlertOutlined",
	// 		title: '预警管理'
	// 	},
	// 	component: layout,
	// 	children: warnManageChild
	// },	
	{
		path: "/system",
		name: "system",
		meta: {
			title: "系统管理",
			permission: "pc_system",
			icon: "SettingOutlined",
		},
		component: layout,
		children: systemChild,
	},
	{
		path: "/taskCenter",
		name: "TaskCenter",
		meta: {
			title: "任务中心",
			permission: "pc_task",
			icon: "SnippetsOutlined",
		},
		component: layout,
		children: [{
			path: 'taskExport',
			name: "taskExport",
			meta: {
				title: '导出任务列表',
				permission: "pc_task_export"
				// permission: "pc_statistics"
			},
			component: () => import("@/views/taskCenter/index.vue")
		},
		{
			path: 'taskImport',
			name: "taskImport",
			meta: {
				title: '导入任务列表',
				permission: "pc_task_import"
				// permission: "pc_statistics"
			},
			component: () => import("@/views/taskCenter/importList.vue")
		}]
	},
	{
		path: "/:pathMatch(.*)*",
		name: "404",
		hidden: true,
		meta: {
			title: '路由不存在'
		},
		component: () => import("@/views/exception/404"),
	},
];

const routes = [
	// ...asyncRoutes,
	...constantRoutes,
];

export const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
	scrollBehavior() {
		// 始终滚动到顶部
		// see https://next.router.vuejs.org/zh/guide/advanced/scroll-behavior.html
		return {
			top: 0
		};
	},
});

// 初始化路由
initRouter();

export default router;
