<template>
	<a-layout class="ui-layout">
		<a-layout-header>
			<myHeader></myHeader>
		</a-layout-header>
		<a-layout style="position: relative;background-color: #FFF;">
			<a-drawer v-if="isH5" :visible="collapsed" class="ui-drawer" width="250" placement="left"
				:closable="false" @close="onChangeCollapsed">
				<a-layout-sider width="250" class="ui-transition">
					<div class="ui-logo" @click="onGotoIndex">
						<img :src="projectSetting?.logo" class="ui-logo__img" />
						<span class="ui-logo__title">{{projectSetting?.name}}</span>
					</div>
					<div class="ui-menu">
						<side :selectedKeys="selectedKeys"></side>
					</div>
				</a-layout-sider>
			</a-drawer>
			<a-layout-sider v-else width="200" class="ui-transition" v-model:collapsed="collapsed">
				<div class="ui-menu">
					<side :selectedKeys="selectedKeys"></side>
				</div>	
			</a-layout-sider>
			<a-layout-content class="ui-main">
				<div class="ui-transition ui-header"
					:style="{
					  position: 'absolute',
					  left: '22px',
					  right: '20px',
					  zIndex: 1,
					}"
				>
					<div id="headerTag" class="ui-tag" v-if="visitedViews.length !== 0">
						<a-tabs style="width: 100%;" size="small" v-model:activeKey="currentPath" type="editable-card" hide-add
							@edit="onEdit" @change="onTabChange">
							<a-tab-pane :key="item.path" v-for="item in visitedViews" :closable="!item?.meta?.affix"
								:tab="!item.meta.title
								? '' : $te(item.meta.title)
								? $t(item.meta.title) : item.meta.title"
							>
								<template #closeIcon>
									<Icon icon="CloseOutlined" :style="{ color: currentPath === item.path ? '#5f91f4' : '#a4a5a5' }"></Icon>
								</template>
							</a-tab-pane>
							<!-- <template #rightExtra>
								<a-dropdown :trigger="['click', 'hover']">
									<span class="ui-tag__close">
										<Icon icon="DownOutlined"></Icon>
									</span>
									<template #overlay>
										<a-menu>
											<a-menu-item @click="onClose(1)" style="color: rgba(0,0,0,.65);">
												<Icon icon="ArrowLeftOutlined"></Icon>
												<span style="padding-left: 6px;">关闭左侧</span>
											</a-menu-item>
											<a-menu-item @click="onClose(2)" style="color: rgba(0,0,0,.65);">
												<Icon icon="ArrowRightOutlined"></Icon>
												<span style="padding-left: 6px;">关闭右侧</span>
											</a-menu-item>
											<a-menu-item @click="onClose(3)" style="color: rgba(0,0,0,.65);">
												<Icon icon="CloseOutlined"></Icon>
												<span style="padding-left: 6px;">关闭其它</span>
											</a-menu-item>
										</a-menu>
									</template>
								</a-dropdown>
							</template> -->
						</a-tabs>
					</div>
				</div>
				<div style="height: 38px;"></div>
				<appMain class="ui-content"></appMain>
				<!-- <a-layout-footer class="ui-footer">copyright 2022 yb</a-layout-footer> -->
			</a-layout-content>
		</a-layout>
	</a-layout>
</template>

<script>
	import {
		mapState
	} from "vuex";
	import eventBus from '@/utils/eventBus.js';
	import { Icon } from '@/components/icon/icon.js';
	import appMain from "./appMain";
	import side from "./side";
	import myHeader from "./header";
	import { getAllUserList, getAllUnitList, getAllDepartmentList, getAllClassifyList } from '@/service/modules/global.js';
	export default {
		components: {
			Icon,
			appMain,
			side,
			myHeader
		},
		data() {
			return {
				openRoutes: [], // 面包屑
				selectedKeys: [], // 左侧导航栏选择
				currentPath: ''
			};
		},
		computed: {
			...mapState("system", ["collapsed"]),
			cachedViews() {
				return this.$store.state.system.cachedViews;
			},
			visitedViews() {
				return this.$store.state.system.visitedViews;
			},
		},
		watch: {
			$route: {
				handler(newVal) {
					if (newVal.path.indexOf("/login") !== -1) return;
					if (newVal.matched && newVal.matched.length) {
						this.openRoutes = newVal.meta.breadcrumbNeste || [];
						this.selectedKeys = this.openRoutes.map((item) => {
							return item.path;
						});
					} else {
						this.openRoutes = [];
						this.selectedKeys = [newVal.name];
					}
					this.currentPath = this.$router.currentRoute.value.path;
				},
				immediate: true,
			},
		},
		created() {
			this.getAllUserList();
			this.getAllUnitList();
			this.getAllDepartmentList();
			this.getAllClassifyList(1);
			this.getAllClassifyList(2);
			this.getAllClassifyList(3);
			this.getAllClassifyList(4);
			this.getAllClassifyList(5);
			
			eventBus.$on('refreshUser', () => {
				this.getAllUserList()
			});
			eventBus.$on('refreshUnin', () => {
				this.getAllUnitList()
			});
			eventBus.$on('refreshDepartment', ()=> {
				this.getAllDepartmentList()
			});
			eventBus.$on('refreshAssetClassify', () => {
				this.getAllClassifyList(1)
			});
			eventBus.$on('refreshMethodList', () => {
				this.getAllClassifyList(2)
			});
			eventBus.$on('refreshUsageList', () => {
				this.getAllClassifyList(3)
			});
			eventBus.$on('refreshUseStatusList', () => {
				this.getAllClassifyList(4)
			});
			eventBus.$on('refreshLiterClassify', () => {
				this.getAllClassifyList(5)
			});
			eventBus.$on('projectScrollTop',()=>{
				this.setPagesScrollTop();
			})
		},
		methods: {
			onGotoIndex() {
				this.$router.replace("/");
			},
			onTagClose(item) {
				this.$store.commit("system/delCachedViews", item);
				if (item.path === this.$router.currentRoute.value.path) {
					if (this.visitedViews.length) {
						this.$router.replace({
							path: this.visitedViews[this.visitedViews.length - 1].path,
							query: this.visitedViews[this.visitedViews.length - 1].query,
						});
					} else {
						if (this.$store.state.system.permissionCodes.includes('pc_jobs')) {
							this.$router.replace("/");
						} else {
							this.$router.replace("/index/welcome");
						}
					}
				}
			},
			onTagChange(item) {
				if (this.$router.currentRoute.value.path === item.path) return;
				this.$router.push({
					path: item.path,
					query: item.query,
				});
			},
			onEdit(e) {
				const item = this.visitedViews.find(tab => {
					return tab.path == e
				});
				this.onTagClose(item);
			},
			onTabChange(e) {
				const item = this.visitedViews.find(tab => {
					return tab.path == e
				});
				this.onTagChange(item);
			},
			onChangeCollapsed() {
				this.$store.commit("system/setCollapsed", !this.collapsed);
			},
			onClose(type) {
				let length = this.visitedViews.length - 1 || 0;
				let index = -1;
				for (let i = length; i >= 0; i--) {
					let item = this.visitedViews[i];
					if (item.path === this.currentPath) {
						index = i;
					} else {
						if (index !== i) {
							if ((index < i && type === 2) || type === 3) {
								if (!item?.meta?.affix) {
									this.$store.commit("system/delCachedViews", item);
								}
							}
							if (index > i && type === 1) {
								if (!item?.meta?.affix) {
									this.$store.commit("system/delCachedViews", item);
								}
							}
						}
					}
				}
			},
			async getAllUserList() {
				let ret = await getAllUserList({});
				if (ret.code === 200) {
					this.$store.commit('count/setUserList', ret.data || []);
				}
			},
			async getAllUnitList() {
				let ret = await getAllUnitList({});
				if (ret.code === 200) {
					this.$store.commit('count/setUnitList', ret.data || []);
				}
			},
			async getAllDepartmentList() {
				let ret = await getAllDepartmentList({});
				if (ret.code === 200) {
					this.$store.commit('count/setDepartmentList', ret.data || []);
				}
			},
			async getAllClassifyList(type) {
				let ret = await getAllClassifyList({
					type: type
				});
				if (ret.code === 200) {
					if (type === 1) {
						let classifyList = ret.data.map(arr => this.mapTree(arr));
						this.$store.commit('count/setAssetClassify', classifyList);
					}
					if (type === 2) {
						this.$store.commit('count/setMethodList', ret.data);
					}
					if (type === 3) {
						this.$store.commit('count/setUsageList', ret.data);
					}
					if (type === 4) {
						this.$store.commit('count/setUseStatusList', ret.data);
					}
					if (type === 5) {
						this.$store.commit('count/setLiterClassify', ret.data);
					}
				}
			},
			mapTree(arr) {
				const haveChild = Array.isArray(arr.children) && arr.children.length > 0;
				return {
					value: arr.id,
					label: arr.name,
					children: haveChild ? arr.children.map(i => this.mapTree(i)) : []
				}
			},
			setPagesScrollTop(){
				let contentDom = document.getElementsByClassName('ui-content')[0];
				contentDom.scrollTop = 0;
			}
		},
	};
</script>

<style lang="less" scoped>
	.ui-menu {
		height: calc(100vh - 72px);
		overflow-y: auto;
		background-color: #1F45A1;
	}

	.ui-menu::-webkit-scrollbar {
		width: 6px;
		height: 0px;
	}

	.ui-menu::-webkit-scrollbar-thumb {
		background-color: #75EFE6;
		border-radius: 10px;
	}

	.ui-layout {
		min-height: 100vh;
	}

	.ui-transition {
		transition: all 0.15s linear;
	}

	.ui-header {
	  padding: 0;
	  right: auto;
	}
	.ui-main {
		// height: calc(100vh - 120px);
		padding: 16px 16px 0 16px;
		overflow: auto;
		background-color: #F5F7FA;
		position: relative;
		box-shadow: 0px 6px 18px 0px rgba(0,0,0,0.06);
	}
	.ui-content {
		height: calc(100vh - 130px);
		padding: 16px;
		background: #FFF;
		border-radius:5px ;
		overflow: auto;
	}
	.ui-footer {
		margin: 0 -20px;
		text-align: center;
	}
	.ui-tag {
		display: flex;
		overflow-x: auto;
		overflow-y: hidden;
		padding: 0px 0px 0px 0px;
		// margin: 0px -20px 0 -20px;
		line-height: 0px;
		text-align: left;
		// background-color: #FFF;

		.ui-tag__item {
			margin-right: 5px;
			padding: 2px 7px;
			cursor: pointer;
		}

		.is-action {
			color: #1890ff;
			background-color: #e8f4ff;
			border-color: #d1e9ff;
		}
	}

	.ui-tag__close {
		padding: 8px 10px;
		margin: 0 6px 0 4px;
		font-size: 16px;
		border-radius: 4px;
		border: solid 1px #eee;
	}

	.ui-tag::-webkit-scrollbar {
		width: 10px;
		height: 5px;
	}

	.ui-tag::-webkit-scrollbar-thumb {
		background-color: #d1e9ff;
		border-radius: 10px;
	}

	.ui-logo {
		display: flex;
		padding: 10px 17px 2px;
		cursor: pointer;
	}

	.ui-logo__img {
		width: 40px;
		height: 40px;
	}

	.ui-logo__title {
		margin: auto 8px;
		font-size: 20px;
		font-weight: 600;
		color: #ffffff;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
</style>
<style>
	.ui-layout .ant-layout-header {
		height: 70px;
		padding: 0 20px;
		line-height: 70px;
		background: #FFF;
		box-shadow: 0px 6px 18px 0px rgba(0,0,0,0.06);
		position: relative;
		z-index: 1;
	}

	.ui-layout .ant-layout-footer {
		padding: 14px;
	}

	.ui-drawer .ant-drawer-body {
		padding: 0;
		background: #001529;
	}

	#headerTag .ant-tabs-top>.ant-tabs-nav {
		margin-bottom: 0;
	}
</style>