let mangerList = getStorage('mangerList',true)
const state = {
	departmentList: [],  // 部门列表
	unitList: [],  // 单位列表
	userList: [],  // 用户列表
	assetClassify: [],	// 资产分类
	methodList: [], // 
	usageList: [], //设备用途
	useStatusList: [], //使用状态
	literClassify: [],  //文献分类
	mangerList,  //主管单位列表
}
const mutations = {
	setUserList(state, value) {
		state.userList = value || [];
	},
	setUnitList(state, value) {
		state.unitList = value || [];
	},
	setMangerList(state, value) {
		state.mangerList = value || [];
		setStorage("mangerList",value,true)
	},
	setDepartmentList(state, value) {
		state.departmentList = value || [];
	},
	setAssetClassify(state, value) {
		state.assetClassify = value || [];
	},
	setMethodList(state, value) {
		state.methodList = value || [];
	},
	setUsageList(state, value) {
		state.usageList = value || [];
	},
	setUseStatusList(state, value) {
		state.useStatusList = value || [];
	},
	setLiterClassify(state, value) {
		state.literClassify = value || [];
	}
}

export default {
  namespaced: true,
  state,
  mutations,
};

function setStorage(key, value, isSession) {
	let obj = {
	  obj: value,
	};
	if (isSession) {
	  window.sessionStorage.setItem(key, JSON.stringify(obj));
	} else {
	  window.localStorage.setItem(key, JSON.stringify(obj));
	}
  }
  
  function getStorage(key, isSession) {
	if (isSession) {
	  if (window.sessionStorage.getItem(key)) {
		return JSON.parse(window.sessionStorage.getItem(key)).obj;
	  } else {
		return undefined;
	  }
	} else {
	  if (window.localStorage.getItem(key)) {
		return JSON.parse(window.localStorage.getItem(key)).obj;
	  } else {
		return undefined;
	  }
	}
  }
