<template>
	<div style="margin: 10px 0 20px 30px;">
		<div v-for="item in list" :key="item.code" v-permission="[item.code]">
			<!-- onSelectChange -->
			<span style="display: inline-block;width: 20px;" >
				<span v-if="item.children && item.children.length" style="padding-right: 4px;cursor: pointer;" @click="item.open = !item.open">
					<Icon :icon="item.open ? 'CaretDownOutlined' : 'CaretRightOutlined'"></Icon>
				</span>
			</span>
			<a-checkbox :checked="selectRowIds.includes(item.code)" :value="item.code" style="padding: 10px 20px 0 0;" @change="(e) => onSelectAll(e, item, false)">
				<span class="ui-title">
					{{ item.name }} 
				</span>
			</a-checkbox>
			<div style="margin: 20px 30px 10px;" v-if="item.type === 'DATA' && selectRowIds.includes(item.code) && dataMap[item.value]">
				<a-select v-model:value="item.dataIdList" mode="multiple" placeholder="请选择" style="min-width: 190px;" @change="(e) => onSelectDataChange(e, item)">
					<a-select-option :value="0">全部</a-select-option>
					<a-select-option v-for="item in dataMap[item.value]" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
				</a-select>
			</div>
			<span v-if="item?.children?.length && item.open">
				<!-- <a-button type="primary" ghost size="small" @click.stop="(e) => onSelectAll(e, item)">全选</a-button> -->
				<span>
					<custemCheckBox :list="item.children" :parentData="item" :selectRowIds="selectRowIds" :dataMap="dataMap" @selectChange="onSelectChange"></custemCheckBox>
				</span>
			</span>
		</div>
	</div>
</template>

<script>
	import {Icon} from '@/components/icon/icon.js';
	export default {
		components: {Icon },
		props: {
			list: {
				type: Array,
				default: () => {
					return []
				}
			},
			selectRowIds: {
				type: Array,
				default: () => {
					return []
				}
			},
			dataMap: {
				type: Object,
				default: () => {
					return {}
				}
			},
			parentData: {
				type: Object,
				default: () => {
					return {}
				}
			}
		},
		data() {
			return {
			}
		},
		created() {
			// console.log("allmemu",this.list)
		},
		methods: {
			onSelectChange(e, item) {
				if (item) {
					let data = {
						checked: e.target.checked,
						id: item.id,
						childrenIds: item.childrenIds || [],
						children: item.children,
						groups: [item.group],
						code: item.code
					}
					if (this.parentData.code) {
						if (!data.groups.includes(this.parentData.code)) {
							data.groups.push(this.parentData.code);
						}
						// data.childrenIds = data.childrenIds.concat([this.parentData.code]);
					}
					this.$emit('selectChange', data);
				} else {
					if (this.parentData.code) {
						if (!e.groups.includes(this.parentData.code)) {
							e.groups.push(this.parentData.code);
						}
						// e.childrenIds = e.childrenIds.concat([this.parentData.code]);
					}
					this.$emit('selectChange', e);
				}
			},
			onSelectAll(e, item) {
				let data = {
					checked: e.target.checked,
					id: item.id,
					childrenIds: item.childrenIds || [],
					children: item.children,
					groups: [item.group],
					selectAll: true,
					code: item.code
				}
				if (this.parentData.code) {
					if (!data.groups.includes(this.parentData.code)) {
						data.groups.push(this.parentData.code);
					}
					// data.childrenIds = data.childrenIds.concat([this.parentData.code]);
				}
				this.$emit('selectChange', data);
			},
			onSelectDataChange(e, data) {
				let emitData = {
					code: data.code,
					dataIdList: e
				}
				if (e.includes(0)) {
					data.dataIdList = [0];
					emitData.dataIdList = [0];
				}
				this.$store.commit('system/setRuleDataIds', emitData);
			}
		}
	}
</script>

<style>
</style>
