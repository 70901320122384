import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import i18n from "./language/index";
import store from "./store";
import antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
//import * as echarts from 'echarts';
import api from "./service/index";
import myMixin from "./utils/base.js";
import checkPermission from "./directive/permission/permission.js";
import sideItem from "./layout/sideItem.vue";
import custemCheckBox from "./views/system/role/components/custemCheckBox.vue";

import DatePicker from 'ant-design-vue/es/date-picker/moment';
import TimePicker from 'ant-design-vue/es/time-picker/moment';
import Calendar from 'ant-design-vue/es/calendar/moment';

const app = createApp({
  ...App,
});

// 全局公用方法注入，有属性方法被页面内重构风险，稍后考虑其它方案注入是否可行
app.mixin(myMixin);

// 全局自定义指令注册
app.directive("permission", checkPermission);

// 全局注入axios
app.config.globalProperties.$axios = api;
// 全局注入echarts
// app.config.globalProperties.$echarts = echarts;

// 左侧菜单栏组件, 提前声明用于循环渲染
app.component("sideItem", sideItem);
app.component('custemCheckBox', custemCheckBox);

app.use(store).use(router).use(i18n).use(antd).use(DatePicker).use(TimePicker).use(Calendar).mount("#app");
