// 资产管理员盘点管理
const children = [
	{
		path: "task",
		name: "inventoryTack",
		meta: {
			title:'盘点任务',
			permission: "pc_inventory_task"
		},
		component: ()=> import('@/views/inventory/task/index.vue')
	}, {
		path: "taskProgress",
		name: "inventoryTaskProgress",
		meta: {
			title:'任务进度',
			permission: "pc_inventory_schedule"
		},
		hidden: true,
		component: ()=> import('@/views/inventory/task/progress.vue')
	}, {
		path: "loss",
		name: "inventoryLoss",
		meta: {
			title: '盘亏管理',
			permission: "pc_inventory_loss"
		},
		component: () => import("@/views/inventory/loss/index.vue")
	}, {
		path: "profit",
		name: "inventoryProfit",
		meta: {
			title: '盘盈管理',
			permission: "pc_inventory_profit"
		},
		component: () => import("@/views/inventory/profit/index.vue")
	},
	{
		path: "taskManage",
		name: "inventoryManageTack",
		meta: {
			title:'盘点任务',
			permission: "pc_inventory_taskManage"
		},
		component: ()=> import('@/views/inventoryManage/task/index.vue')
	}, {
		path: "taskProgressManage",
		name: "inventoryManageTaskProgress",
		meta: {
			title:'盘点调度',
			permission: "pc_inventory_dispatch"
		},
		hidden: true,
		component: ()=> import('@/views/inventoryManage/task/progress.vue')
	}, {
		path: "lossManage",
		name: "inventoryManageLoss",
		meta: {
			title: '盘亏管理',
			permission: "pc_inventory_lossManage"
		},
		component: () => import("@/views/inventoryManage/loss/index.vue")
	}, {
		path: "profitManage",
		name: "inventoryManageProfit",
		meta: {
			title: '盘盈管理',
			permission: "pc_inventory_profitManage"
		},
		component: () => import("@/views/inventoryManage/profit/index.vue")
	}, {
		path: "resultManage",
		name: "inventoryManageResult",
		meta: {
			title: '盘点结果',
			permission: "pc_inventory_result"
		},
		component: () => import("@/views/inventoryManage/result/index.vue")
	}
]

export default children;