// 资产管理员资产管理
const children = [
	{
		path: "myself",
		name: "propertyMyself",
		meta: {
			title: '我的资产',
			permission: "pc_assets_myself"
		},
		component: () => import("@/views/property/myself/index.vue")
	}, {
		path: "transfer",
		name: "propertyTransfer",
		meta: {
			title:'资产转移',
			permission: "pc_assets_transfer"
		},
		component: ()=> import("@/views/property/transfer/index.vue")
	}, {
		path: "borrow",
		name: "propertyBorrow",
		meta: {
			title: '我的借入',
			permission: "pc_assets_borrow"
		},
		component: () => import("@/views/property/borrow/index.vue")
	}, {
		path: "lend",
		name: "propertyLend",
		meta: {
			title: '我的借出',
			permission: "pc_assets_lend"
		},
		component: () => import("@/views/property/lend/index.vue")
	}, {
		path: "transIn",
		name: "propertyTransIn",
		meta: {
			title: '调入资产',
			permission: "pc_assets_callin"
		},
		component: () => import("@/views/property/transIn/index.vue")
	}, {
		path: "transOut",
		name: "propertyTransOut",
		meta: {
			title: '调出资产',
			permission: "pc_assets_callout"
		},
		component: () => import("@/views/property/transOut/index.vue")
	}, {
		path: "accounts",
		name: "propertyAccounts",
		meta: {
			title: '下账管理',
			permission: "pc_assets_registration"
		},
		component: () => import("@/views/property/accounts/index.vue")
	}, {
		path: "scrap",
		name: "propertyScrap",
		meta: {
			title: '报废管理',
			permission: "pc_assets_scrap"
		},
		component: () => import("@/views/property/scrap/index.vue")
	}, {
		path: "propertManage",
		name: "propertyManageMyself",
		meta: {
			title: '资产管理',
			permission: "pc_assets_assets"
		},
		component: () => import("@/views/propertyManage/myself/index.vue")
	}, {
		path: "allocateManage",
		name: "propertyManageAllocate",
		meta: {
			title: '资产调拨',
			permission: "pc_assets_transferManage"
		},
		component: () => import("@/views/propertyManage/allocate/index.vue")
	}, {
		path: "borrowAndLendManage",
		name: "propertyManageBorrowAndLend",
		meta: {
			title: '借入借出',
			permission: "pc_assets_inout"
		},
		component: () => import("@/views/propertyManage/borrowAndLend/index.vue")
	}, {
		path: "accountsManage",
		name: "propertyManageAccounts",
		meta: {
			title: "下账管理",
			permission: "pc_assets_accountsManage"
		},
		component: () => import("@/views/propertyManage/accounts/index.vue")
	}, {
		path: "scrapManage",
		name: "propertyManageScrap",
		meta: {
			title: "报废管理",
			permission: "pc_assets_scrapManage"
		},
		component: () => import("@/views/propertyManage/scrap/index.vue")
	}
];

export default children;