<template>
  <a-config-provider :locale="locales">
    <router-view></router-view>
  </a-config-provider>
</template>

<script>
import { messages } from "@/language/index";
import dayjs from "dayjs";
export default {
  computed: {
    locales() {
      // 语言包设置
      const locale = messages[this.$store.state.system.language] || messages["zh"];
      dayjs.locale(locale);  // antd日期组件语言包设置
      return locale;
    },
  },
  data(){
    return {
      timeOut: null
    }
  },
  mounted() {
	this.windowSystem(window?.innerWidth);
    window.onresize = (e) => {
      if (this.timeOut) {
        clearTimeout(this.timeOut);
      }
      this.timeOut = setTimeout(() => {
        this.timeOut = null;
        this.windowSystem(e.target.innerWidth);
      }, 300);
    }
  },
  methods: {
    windowSystem(width) {
      this.$store.commit("system/setIsH5", width < 769);
    }
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

* {
	word-break: break-all;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

/* .label-width .ant-form-item-label {
	width: 82px;
}
 */
.ui-form__item {
  margin-right: 30px !important;
  margin-bottom: 20px !important;
}

.table .ant-table-thead > tr > th {
	background-color: #F5F7FA !important;
	/* border-right: 0px !important; */
}
/* .table .ant-table-tbody > tr > td {
	border-right: 0px !important;
} */

.ui-wrap__reverse {
  flex-wrap: wrap-reverse !important;
}

.ui-search__btn {
  margin-bottom: 0;
  text-align: right;
}

.ant-btn + .ant-btn {
	margin-left: 16px;
}

@media screen and (max-width: 768px) {
  .ui-search__form {
    display: block !important;
  }
  .ui-form__item {
    display: flex !important;
    margin-right: 0 !important;
  }
  .ui-search__btn {
    margin-bottom: 20px;
  }
  .ant-form .ui-form__item .ant-form-item-label {
    text-align: left;
    flex: 0 0 25% !important;
  }
  .ant-form .ui-form__item .ant-form-item-control {
    flex: 1 !important;
  }
}
</style>
