import { send } from "../index";
// 全部用户列表
export function getAllUserList(data, opts = {}) {
  return send({
    url: "/admin/adminUser/allList.do",
    method: "POST",
    data,
    ...opts,
  });
}
// 全部单位列表
export function getAllUnitList(data, opts = {}) {
  return send({
    url: "/admin/unit/allList.do",
    method: "POST",
    data,
    ...opts,
  });
}
// 全部部门列表
export function getAllDepartmentList(data, opts = {}) {
  return send({
    url: "/admin/department/allList.do",
    method: "POST",
    data,
    ...opts,
  });
}
// 全部参数列表
export function getAllClassifyList(data, opts = {}) {
  return send({
    url: "/admin/basicsParameter/searchListNoPage.do",
    method: "POST",
    data,
    ...opts,
  });
}
