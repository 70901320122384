// 资产管理员预警管理
const children = [
	{
		path: "banner",
		name: "systemBanner",
		meta: {
			title: '轮播图管理',
			permission: "pc_system_banner"
		},
		component: ()=> import("@/views/system/banner/list.vue")
	},
	{
		path: "literature",
		name: "systemLiterature",
		meta: {
			title: '文献管理',
			permission: "pc_system_literature"
		},
		component: () => import("@/views/system/literature/index.vue")
	},
	{
		path: "role",
		name: "systemRole",
		meta: {
			title: '角色管理',
			permission: "pc_system_roles"
		},
		component: () => import("@/views/system/role/index.vue")
	}, {
		path: "user",
		name: "systemUser",
		meta: {
			title: '用户管理',
			permission: "pc_system_user"
		},
		component: () => import("@/views/system/user/index.vue")
	}, {
		path: "institution",
		name: "systemInstitution",
		meta: {
			title: '单位管理',
			permission: "pc_system_unit"
		},
		component: () => import("@/views/system/institution/index.vue")
	}, {
		path: "department",
		name: "systemDepartment",
		meta: {
			title: '部门管理',
			permission: "pc_system_department"
		},
		component: () => import("@/views/system/department/index.vue")
	}, {
		path: "log",
		name: "systemLog",
		meta: {
			title: '日志管理',
			permission: "pc_system_log"
		},
		component: () => import("@/views/system/log/index.vue")
	}, {
		path: "opinion",
		name: "systemOpinion",
		meta: {
			title: '意见管理',
			permission: "pc_system_recommend"
		},
		component: () => import("@/views/system/opinion/index.vue")
	}, {
		path: "notice",
		name: "systemNotice",
		meta: {
			title: '通知公告',
			permission: "pc_system_notice"
		},
		component: () => import("@/views/system/notice/index.vue")
	}, {
		path: "staff",
		name: "systemStaff",
		meta: {
			title: '人员管理',
			permission: "pc_system_member"
		},
		component: ()=> import("@/views/system/staff/index.vue")
	}, {
		path: "option",
		name: "systemOption",
		meta: {
			title: '参数设置',
			permission: "pc_system_options"
		},
		component: () => import("@/views/system/option/index.vue")
	}, 
	{
		path: "warnTime",
		name: "warnTime",
		meta: {
			title: '预警时间',
			permission: "pc_system_time"
			// permission: "pc_system_options"
		},
		component: () => import("@/views/system/warnTime/index.vue")
	}, 
	{
		path: "agreement",
		name: "systemAgreement",
		meta: {
			title: '协议说明',
			permission: "pc_system_agreement"
		},
		component: () => import("@/views/system/agreement/list.vue")
	},
	{
		path: "menu",
		name: "systemMenu",
		meta: {
			title: "权限管理",
			permission: "pc_system_power"
		},
		component: () => import("@/views/system/menu/list.vue")
	}, {
		path: "power",
		name: "systemRolePower",
		meta: {
			title: '授权',
			permission: "pc_system_roles_power"
		},
		hidden: true,
		component: () => import("@/views/system/role/power.vue")
	}
]

export default children;