const children = [
    {
        path:"projectMain",
        name:"projectMain",
        meta:{
            title:"项目管理",
			permission: "pc_project_manger"
        },
        component: () => import('@/views/project/index.vue') 
    },{
        path:"projectRecord",
        name:"projectRecord",
        meta:{
            title:"录入项目",
			permission: "pc_project_enter"
        },
		hidden: true,
        component: ()=> import('@/views/project/record/index.vue')
    },{
        path:"projectState",
        name:"projectState",
        meta:{
            title:"工程状态",
			permission: "pc_project_status"
        },
        component: ()=> import('@/views/project/state/index.vue')
    },
	{
        path:"projectAnalysis",
        name:"projectAnalysis",
        meta:{
            title:"数据分析",
			permission: "pc_project_analyse"
        },
        component: ()=> import('@/views/project/analysis/index.vue')
    },
	{
        path:"projectApply",
        name:"projectApply",
        meta:{
            title:"修改申请",
			permission: 'pc_project_urequest'
        },
        component: ()=> import('@/views/project/apply/index.vue')
    },{
        path:"projectLog",
        name:"projectLog",
        meta:{
            title:"项目日志",
			permission: "pc_project_log"
        },
        component: ()=> import('@/views/project/log.vue')
    },
	// {
 //        path:"projectManage",
 //        name:"projectManage",
 //        meta:{
 //            title:"在建工程管理"
 //        },
 //        component: ()=> import('@/views/project/manage.vue')
 //    },
	{
        path:"projectSearch",
        name:"projectSearch",
        meta:{
            title:"项目查询",
			permission: 'pc_project_search'
        },
        component: ()=> import('@/views/project/search/index.vue')
    },
	{
	    path:"projectPreview",
	    name:"projectPreview",
	    meta:{
	        title:"项目预览"
	    },
		hidden: true,
	    component: ()=> import('@/views/project/preview/index.vue')
	}
]

export default children;