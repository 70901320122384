<template>
  <div class="ui-flex">
    <div class="ui-left">
		<img class="ui-head-logo" src="@/assets/image/logo.png"/>
		<div class="ui-head-title">
			上栗县行政事业性国有资产台账管理系统
		</div>
    </div>
    <div class="ui-right">

		<div class="ui-login__user">
		  <div class="ui-login__icon"><Icon icon="UserOutlined"></Icon></div>
		  <div class="ui-login__name">{{ userInfo?.name || '用户名称' }}</div>
		  <!-- <Icon icon="DownOutlined"></Icon> -->
		</div>
      <!-- <a-dropdown>
        <div class="ui-login__user">
          <div class="ui-login__icon"><Icon icon="UserOutlined"></Icon></div>
          <div class="ui-login__name">{{ userInfo?.name || '用户名称' }}</div>
		  <Icon icon="DownOutlined"></Icon>
        </div>
        <template #overlay>
          <a-menu>
            <a-menu-item @click="onLogout">
              <div>退出登录</div>
            </a-menu-item>
          </a-menu>
        </template>
      </a-dropdown> -->
	  
	  <div class="ui-department">
		  <span>{{ userInfo?.unitName || '用户单位' }}</span>
		  |
		  <span>{{ userInfo?.dept || '用户部门' }}</span>
	  </div>
	  
	  <img class="ui-logout" src="@/assets/image/logout.png" @click="onLogout"/>
    </div>
  </div>
</template>

<script>
import { Icon } from "@/components/icon/icon.js";
import { mapState } from "vuex";
import { defineComponent } from "vue";
import i18n from "@/language/index";
import { initRouter } from "@/utils/routerUtil";

export default defineComponent({
  components: { Icon },
  computed: {
    ...mapState("system", ["userInfo", "collapsed"]),
  },
  created() {
  	// console.log('user',this.userInfo)
  },
  methods: {
    onLogout() {
		this.$confirm({
			title: '提示',
			content: '确定退出吗？',
			onOk: async() => {
				// 清除vuex中的用户登录信息
				this.$store.dispatch("system/logout");
				// 清除缓存中的用户登录信息
				await window.sessionStorage.clear();
				// 重置路由
				initRouter();
				this.$router.replace("/login");
			}
		})
    },
    onChangeCollapsed() {
      this.$store.commit("system/setCollapsed", !this.collapsed);
    },
  },
});
</script>

<style lang="less" scoped>
.ui-flex {
  display: flex;
  align-items: center;
  user-select: none;
}
.ui-head-logo {
	width: 50px;
	// height: 47px;
}
.ui-head-title {
	margin-left: 10px;
	font-size: 26px;
	font-weight: 400;
	color: #333333;
	line-height: 47px;
}
.ui-left {
  display: flex;
  flex: 1;
  align-items: center;
}
.ui-left__collapsed {
  font-size: 24px;
  color: #ffffff;
  cursor: pointer;
}
.ui-right {
  display: flex;
  align-items: center;
}
.ui-lang {
  margin-right: 20px;
  font-size: 22px;
  color: #ffffff;
  cursor: pointer;
}
.ui-bread {
  margin-left: 20px;
  padding-top: 13px;
  text-align: left;

  span {
    cursor: pointer;
  }
}
.ui-login__user {
  display: flex;
  margin-right: 36px;
  text-align: center;
  color: #333333;
  align-items: center;
  cursor: pointer;
}
.ui-login__icon {
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: 17px;
  background: #ccc;
  border-radius: 50%;
}
.ui-login__name {
  font-size: 14px;
  padding: 0 8px;
}
.ui-department {
	font-size: 14px;
	font-weight: 400;
	color: #333333;
	line-height: 16px;
}
.ui-department span {
	padding: 0 10px;
}
.ui-logout {
	width: 24px;
	height: 24px;
	margin-right: 10px;
	margin-left: 40px;
	cursor: pointer;
}
</style>

<style>
.ui-left a {
  color: rgba(255, 255, 255, 0.9) !important;
}
.ui-left span {
  color: rgba(255, 255, 255, 0.6);
}
</style>
