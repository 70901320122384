// 资产管理员预警管理
const children = [
	{
		path: "warning",
		name: "inventoryWarning",
		meta: {
			title: '到期预警',
			permission: "pc_warn_due"
		},
		component: () => import("@/views/warn/warning/index.vue")
	}, {
		path: "lossWarn",
		name: "inventoryLossWarn",
		meta: {
			title: '盘亏预警',
			permission: "pc_warn_loss"
		},
		component: () => import("@/views/warn/lossWarn/index.vue")
	},
	{
		path: "warningManage",
		name: "warnManageWarning",
		meta: {
			title: '到期预警',
			permission: "pc_warn_dueManage"
		},
		component: () => import("@/views/warnManage/warning/index.vue")
	}, {
		path: "lossWarnManage",
		name: "warnManageLossWarn",
		meta: {
			title: '盘亏预警',
			permission: "pc_warn_lossManage"
		},
		component: () => import("@/views/warnManage/lossWarn/index.vue")
	}
]

export default children;